@use '../../../../../../../variables';

.applicant-details-step-tips {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    row-gap: 20px;

    img {
        width: 100%;
    }

    h4 {
        margin-bottom: 0;
    }

    p {
        margin: 0;
    }
}

@media screen and (max-width: variables.$md) {
    .applicant-details-step-tips {
        h4, p {
            font-size: 15px;
        }
    }
}

@media screen and (max-width: variables.$sm) {
    .applicant-details-step-tips {
        grid-template-columns: auto;
    }
}
