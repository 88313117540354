@use '../../../../variables';

.header-container {
    padding: 50px 30px;

    img {
        max-width: 150px;
    }
}

@media screen and (max-width: variables.$lg) {
    .header-container {
        padding: 50px 15px;

        img {
            max-width: 130px;
        }
    }
}

@media screen and (max-width: variables.$md) {
    .header-container {
        padding: 20px;
        text-align: center;

        img {
            max-width: 130px;
        }
    }
}

@media screen and (max-width: variables.$sm) {
    .header-container {
        padding: 10px;

        img {
            max-width: 125px;
        }
    }
}
