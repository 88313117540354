.terms-and-conditions {

    p,
    li,
    td {
        font-size: 0.8em;
    }

    h2 {
        font-size: 1.1em;
        font-weight: 500;
    }

    h3 {
        font-weight: 500;
        font-size: 0.9em;
    }

    td li {
        font-size: 1em;
    }


    table.styled-table, table.styled-table td {
        text-align: center;
        border: 1px solid #000;
        border-collapse: collapse;
        padding: 5px;
    }
}
