@use '../../../../../variables';

.summary-section {
    margin-bottom: 20px;
}

.summary-section-header {
    display: flex;
    justify-content: space-between;

    h3 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        margin: 0 0 10px;
    }
}

.summary-section-content {
    background-color: #f2f2f2;
    padding: 12px;
    border-radius: 10px;
    font-size: 14px;
}

.summary-section-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 5px;

    >*:nth-child(odd) {
        font-weight: 500;
    }
}

@media screen and (max-width: variables.$sm) {
    .summary-section-grid {
        grid-template-columns: 1fr;

        >div:nth-child(even) {
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
