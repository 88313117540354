@use '../../../../../variables';

.representatives-step-container {

    .btn-with-icon {
        line-height: 1;
    }

    ul {
        margin: 0;
    }

    h2 {
        &:first-of-type {
            margin-top: 10px;
        }
    }
}

.representatives-step-loading {
    text-align: center;
}

.representatives-step-btn {
    text-align: right;
    margin: 15px 0;
}

.representatives-step-checkhelper {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.6);

    p {
        margin: 3px 0;
    }

    li {
        margin: 4px 0;
    }
}

.representatives-step-user-confirmation {
    text-align: center;
}
