.data-protection-policy {

    p,
    li,
    td {
        font-size: 0.8em;
    }

    h2 {
        font-size: 1.1em;
        font-weight: 500;
    }

    h3 {
        font-weight: 500;
        font-size: 0.9em;
    }
}
