html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Material-UI overrides

.MuiFormControlLabel-label {
  text-align: left;
}

.MuiAlert-standardError ul,
.MuiAlert-message ul {
  padding: 0;
  list-style: inside;
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 12px;
}

// Dropzone overrides

.dropzone-ui {
  >.dz-ui-label {
    font-size: 16px;
    font-family: inherit;
  }

  &:hover {
    border-style: solid;
  }
}
