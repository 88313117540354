@use '../../../variables';

.onboarding-page-container-loading {
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.onboarding-page-container-loaded {
    display: grid;
    grid-template-columns: 370px auto;
    min-height: 100vh;
}

h2 {
    font-size: 1.2em;
    font-weight: 400;
    margin: 25px 0 20px;
}

.wizard {
    padding-top: 50px;
    padding-bottom: 50px;
}

.wizard-stepper {
    margin-bottom: 40px;

    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 500;
    }
}

.wizard-step-introduction {
    text-align: center;
    margin-bottom: 40px;

    p {
        margin-top: 0;
        margin-bottom: 5px;
    }
}

.wizard-step-fields {
    max-width: 370px;
    margin: 0 auto 30px;
    text-align: left;

    .MuiGrid-item {
        margin-bottom: 0 !important;
    }
}

.wizard-step-btn {
    text-align: center;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0;
    padding: 15px;
    background: #FFF;
    z-index: 1;

    button {
        margin: 0 5px;
    }
}

@media screen and (max-width: variables.$lg) {
    .onboarding-page-container-loaded {
        grid-template-columns: 270px auto;
    }
}

@media screen and (max-width: variables.$md) {
    .onboarding-page-container-loaded {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        min-height: auto;
    }
}

@media screen and (max-width: variables.$sm) {
    .onboarding-page-container-loaded {
        .wizard {
            padding-top: 35px;
            padding-bottom: 35px;
        }

        .wizard-stepper {
            display: none;
        }
    }
}
