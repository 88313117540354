.business-type-step-fields {
    max-width: 370px;
    margin: 50px auto 30px;
    text-align: left;
}

.business-type-step-btn {
    text-align: center;
    margin-top: 15px;
    display: flex;
    justify-content: center;

    button {
        margin: 0 5px;
    }

    &.sticky {
        position: sticky;
        bottom: 0;
        padding: 15px;
        background: #FFF;
        z-index: 1;
    }
}
