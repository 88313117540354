@use '../../../../../../variables';

.representatives-summary-grid {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 5px;

    >*:nth-child(3n + 1) {
        font-weight: 500;
    }
}

.representatives-fields {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 25px;
}

@media screen and (max-width: variables.$sm) {
    .representatives-summary-grid {
        grid-template-columns: 1fr;

        >div:nth-child(3n) {
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
